<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
        <div class="col-sm-12 down">
            <h1 class="intro-section-title">3. Ziekenhuisopnamen - Nationale Cijfers</h1>
        </div>
      </div>


        <div class="row">
          <div class="col-sm-6 down">
            <card :cardId="'Herkomst voor ziekenhuisopname, nationale cijfers'" :hovertext="'Waar verbleef de patient voor de ziekenhuisopname (volgens het ziekenhuis): thuis, in een verpleeghuis of elders. In deze grafiek wordt uitsluitend gekeken naar mensen met dementie.'" 
              :x="jaar_wmo" :y ="categorie_wmo" :z="percentage_wmo.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Categorie'" :ztitle="'Percentage'"
              :tableHeight="450">
              <template slot="card-header">Herkomst voor ziekenhuisopname, nationale cijfers</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in de herkomst van mensen voordat ze worden opgenomen in het ziekenhuis </p>
                  <stacked-bar-chart-colors-x-t v-if="load.graphOne" :ytitle="'% herkomst'" :xtitle="'Jaar'" :ticksuffix="'%'"
                  :name1="'Verpleeghuis'" :tickangle="0"
                  :x1="jaar_verpleeghuis"
                  :y1="verpleeghuis"
                  :name2="'Thuis'"
                  :x2="jaar_thuis"
                  :y2="thuis"
                  :name3="'Overig'"
                  :x3="jaar_overig"
                  :y3="overig"
                  :marginBottom="50"/>
              </div>
            </card>
          </div>
          <div class="col-sm-6 down">
            <card :cardId="'Bestemming na ziekenhuisopname, nationale cijfers'" :hovertext="'Waar verbleef de patient na de ziekenhuisopname (volgens het ziekenhuis): thuis, in een verpleeghuis, hospice, revalidatiecentrum of elders. In deze grafiek wordt uitsluitend gekeken naar mensen met dementie.'" 
              :x="jaar_ziekenhuisopname" :y ="categorie_ziekenhuisopname" :z="percentage_ziekenhuisopname.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Categorie'" :ztitle="'Percentage'"
              :tableHeight="550">
              <template slot="card-header">Bestemming na ziekenhuisopname, nationale cijfers</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in de bestemming van mensen nadat ze zijn opgenomen in het ziekenhuis </p>
                  <stacked-bar-chart-colors-x-t v-if="load.graphTwo" :ytitle="'% bestemming'" :xtitle="'Jaar'" :ticksuffix="'%'"
                  :name1="'Verpleeghuis'" :tickangle="0"
                  :x1="jaar_verpleeghuis_bst"
                  :y1="verpleeghuis_bst"
                  :name2="'Thuis'"
                  :x2="jaar_thuis_bst"
                  :y2="thuis_bst"
                  :name3="'Hospice'"
                  :x3="jaar_hospice_bst"
                  :y3="hospice_bst"
                  :name4="'Revalidatiecentrum'"
                  :x4="jaar_revalidatiecentrum_bst"
                  :y4="revalidatiecentrum_bst"
                  :name5="'Overig'"
                  :x5="jaar_overig_bst"
                  :y5="overig_bst"
                  :marginBottom="50"/>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 down">
            <card :cardId="'Percentage met minimaal 1 ziekenhuisopname'" :hovertext="'In deze grafiek wordt uitsluitend gekeken naar mensen met dementie.'"
             :x="jaar" :y ="perc_minstens_een_opname_table.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Percentage'"  :tableHeight="300">
              <template slot="card-header">Percentage met minimaal 1 ziekenhuisopname</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in het percentage mensen met dementie dat minimaal 1 ziekenhuisopname heeft </p>
                  <stacked-bar-chart-colors-x-t v-if="load.graphThree" :ytitle="'% met minimaal 1 opname'" :xtitle="'Jaar'" :ticksuffix="'%'"
                  :name1="'Minimaal 1 opname'" :tickangle="0"
                  :x1="jaar"
                  :y1="perc_minstens_een_opname"
                  :showlegend="false"
                :marginBottom="50" />
              </div>
            </card>
          </div>
          <div class="col-sm-6 down">
            <card :cardId="'Gemiddeld aantal ziekenhuisopnames'" :hovertext="'In deze grafiek wordt uitsluitend gekeken naar mensen met dementie die minimaal 1 ziekenhuis opname hebben gehad in het betreffende jaar.'" 
              :x="jaar" :y ="gemiddelde_opnames.map(value => `${value}`)"
              :xtitle="'Jaar'" :ytitle="'Percentage'"  :tableHeight="300">
              <template slot="card-header">Gemiddeld aantal ziekenhuisopnames</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in het gemiddeld aantal ziekenhuisopnames van mensen met dementie die ten minste 1x zijn opgenomen in het geselecteerde jaar </p>
                  <stacked-bar-chart-colors-numbers v-if="load.graphThree" :ytitle="'Gemiddeld aantal opnames'" :xtitle="'Jaar'"
                  :name1="'Gemiddeld aantal opnames'"
                  :x1="jaar"
                  :y1="gemiddelde_opnames"
                  :showlegend="false"
                :marginBottom="50" />
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 down">
            <card :cardId="`Type opname in ziekenhuis in ${selectedYear}`" v-if="selectedYear!==2022" :hovertext="'Type opname: dagopname, langdurige observatie of klinische opname. Het percentage wordt berekend als deel van het totaal aantal opnames in het geselecteerde jaar van de betreffende bevolkingsgroep. Data over mensen met dementie komt uit de CBS microdata. De bron voor de data over de algemene bevolking is: https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84069NED/table?dl=51D78'" 
              :x="categorie_opname" :y="groep_opname" :z="percentage_opname.map(value => `${value}%`)"
              :xtitle="'Categorie'" :ytitle="'Groep'" :ztitle="'Percentage'"  :tableHeight="500">
              <template slot="card-header">Type opname in ziekenhuis in {{selectedYear}}</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in het type opname: het percentage dagopname, langdurige observatie of klinische opname van alle opnames in het geselecteerde jaar </p>
                  <stacked-bar-chart-colors-x-t v-if="load.graphSix" :ytitle="'% soort opname'" :xtitle="'Bevolkingsgroep'" :tickangle="0" :ticksuffix="'%'"
                  :name1="'Dagopname'"
                  :x1="groep_dag"
                  :y1="dag"
                  :name2="'Klinische opname'"
                  :x2="groep_klinisch"
                  :y2="klinisch"
                  :name3="'Langdurige observatie'"
                  :x3="groep_observaties"
                  :y3="observaties"/>
              </div>
            </card>
            <card :cardId="`Type opname in ziekenhuis in ${selectedYear}`" v-if="selectedYear === 2022">
              <template slot="card-header">Type opname in ziekenhuis in {{selectedYear}} - niet beschikbaar voor {{selectedYear}}
              </template>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 down">
            <card :cardId="`Percentage acute opnames in ${selectedYear}`" v-if="selectedYear!==2022" :hovertext="'Specifiek wordt gekeken naar acute klinische opnames die als “urgent” zijn aangemerkt, en dus niet gepland waren als percentage van het totaal aantal klinische opnames. Data over mensen met dementie komt uit de CBS microdata. De bron voor de data over de algemene bevolking is: https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84522NED/table?dl=51F68'" 
              :x="groep_acuut" :y ="perc_acuut_table.map(value => `${value}%`)"
              :xtitle="'Groep'" :ytitle="'Percentage'"  :tableHeight="300">
              <template slot="card-header">Percentage acute opnames in {{selectedYear}}</template>
              <div slot="card-body">
                  <p class="graphtext">Deze grafiek geeft inzicht in het percentage klinische opnames dat als “urgent” is aangemerkt, en dus niet gepland was maar acuut.</p>
                  <stacked-bar-chart-colors-x-t v-if="load.graphFive" :ytitle="'% acute klinische opnames'" :xtitle="'Bevolkingsgroep'" :tickangle="0" :ticksuffix="'%'"
                  :name1="'% acute opnames'"
                  :x1="groep_acuut"
                  :y1="perc_acuut"
                  :showlegend="false"/>
              </div>
            </card>
            <card :cardId="`Percentage acute opnames in ${selectedYear}`" v-if="selectedYear === 2022">
              <template slot="card-header">Percentage acute opnames in {{selectedYear}} - niet beschikbaar voor {{selectedYear}}</template>
            </card>
          </div>
          <div class="col-sm-6 down">
            <card :cardId="`Gemiddelde ligduur bij klinische opname in ${selectedYear}`" v-if="selectedYear!==2022" :hovertext="'In deze grafiek wordt gekeken naar het gemiddeld aantal dagen dat iemand in het ziekenhuis ligt. Data over mensen met dementie komt uit de CBS microdata. De bron voor de data over de algemene bevolking is: https://opendata.cbs.nl/#/CBS/nl/dataset/84069NED/table?dl=2C798'" 
              :x="groep_ligduur" :y ="ligduur.map(value => `${value}`)"
              :xtitle="'Groep'" :ytitle="'Aantal'"  :tableHeight="300">
              <template slot="card-header">Gemiddelde ligduur bij klinische opname in {{selectedYear}}</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in het gemiddeld aantal dagen dat iemand in het ziekenhuis ligt (bij klinische opname).</p>
                  <stacked-bar-chart-colors-numbers v-if="load.graphFour" :ytitle="'Gemiddelde ligduur'" :xtitle="'Bevolkingsgroep'"
                  :name1="'Gemiddelde ligduur'"
                  :x1="groep_ligduur"
                  :y1="ligduur"
                  :showlegend="false"/>
              </div>
            </card>
            <card :cardId="'Gem ligduur bij klinische opname'" v-if="selectedYear===2022">
              <template slot="card-header">Gem. ligduur bij klinische opname - niet beschikbaar voor {{selectedYear}}
              </template>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 down">
            <card :cardId="`Top 10 hoofddiagnosen mensen met dementie in ${selectedYear}`" v-if="selectedYear!==2020 && selectedYear!==2021 && selectedYear!==2022" :hovertext="'In deze grafiek wordt naar de totale jaarlijkse ziekenhuisopnames van mensen met dementie gekeken. De data komt uit de CBS microdata. Het gaat over de ICD-10 code zoals gerapporteerd in de LBZ-data.'" 
              :x="diagnose_dementie" :y ="aantal_dementie.map(value => `${value}`)"
              :xtitle="'Groep'" :ytitle="'Aantal'"  :tableHeight="500">
              <template slot="card-header" >Top 10 hoofddiagnosen mensen met dementie in {{selectedYear}}</template>
              <div slot="card-body">
                Deze grafiek geeft de top 10 hoofddiagnosen weer voor mensen met dementie in {{selectedYear}}.
                 <horizontal-bar-chart-numbers v-if="load.graphSeven" :xtitle="'Aantal opnames met hoofddiagnose per 100 mensen met dementie'" :x="aantal_dementie" :y="diagnose_dementie" :marginleft="300"/>
              </div>
            </card>
            <card :cardId="`Top 10 hoofddiagnosen mensen met dementie in ${selectedYear}`" v-if="selectedYear===2020 || selectedYear===2021 || selectedYear===2022">
              <template slot="card-header">Top 10 hoofddiagnosen mensen met dementie in {{selectedYear}} - niet beschikbaar voor {{selectedYear}}</template>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 down">
            <card :cardId="`Top 10 hoofddiagnosen mensen algemene bevolking 80+ ${selectedYear}`" v-if="selectedYear!==2022"
            :hovertext="'In deze grafiek wordt naar de totale jaarlijkse ziekenhuisopnames van mensen boven de 80 jaar gekeken. Bron voor deze data is: https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84069NED/table?dl=51F3B'"
              :x="diagnose_80" :y ="aantal_80.map(value => `${value}`)"
              :xtitle="'Groep'" :ytitle="'Aantal'"  :tableHeight="450">
              <template slot="card-header" >Top 10 hoofddiagnosen mensen algemene bevolking 80+ in {{selectedYear}}</template>
              <div slot="card-body">
                Deze grafiek geeft de top 10 hoofddiagnosen weer voor iedereen in de algemene bevolking ouder dan 80 jaar in {{selectedYear}}.
                <horizontal-bar-chart-numbers v-if="load.graphSeven" :xtitle="'Aantal opnames met hoofddiagnose per 100 mensen ouder dan 80'" :x="aantal_80" :y="diagnose_80" :marginleft='300'/>
              </div>
            </card>
            <card :cardId="`Top 10 hoofddiagnosen mensen algemene bevolking 80+ in ${selectedYear}`" v-if="selectedYear===2022">
              <template slot="card-header">Top 10 hoofddiagnosen mensen algemene bevolking 80+ in {{selectedYear}} - niet beschikbaar voor {{selectedYear}}</template>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 down">
            <card :cardId="`Top 10 hoofddiagnosen mensen algemene bevolking 65+ in ${selectedYear}`" v-if="selectedYear!==2022"
            :hovertext="'In deze grafiek wordt naar de totale jaarlijkse ziekenhuisopnames van mensen boven de 65 jaar gekeken. Bron voor deze data is: https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84069NED/table?dl=51F43'" 
              :x="diagnose_65" :y ="aantal_65.map(value => `${value}`)"
              :xtitle="'Groep'" :ytitle="'Aantal'"  :tableHeight="450">
              <template slot="card-header" >Top 10 hoofddiagnosen mensen algemene bevolking 65+ in {{selectedYear}}</template>
              <div slot="card-body">
                Deze grafiek geeft de top 10 hoofddiagnosen weer voor iedereen in de algemene bevolking ouder dan 65 jaar in {{selectedYear}}.
                 <horizontal-bar-chart-numbers v-if="load.graphSeven" :xtitle="'Aantal opnames met hoofddiagnose per 100 mensen ouder dan 65'" :x="aantal_65" :y="diagnose_65" :marginleft="300"/>
              </div>
            </card>
            <card :cardId="`Top 10 hoofddiagnosen mensen algemene bevolking 65+ in ${selectedYear}`" v-if="selectedYear===2022">
              <template slot="card-header">Top 10 hoofddiagnosen mensen algemene bevolking 65+ in {{selectedYear}} - niet beschikbaar voor {{selectedYear}}</template>
            </card>
          </div>
        </div>

        <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>
//
// import SideBarRight from '../components/SideBarRight'
import StackedBarChartColorsXT from '../components/StackedBarChartColorsXTitle'
import StackedBarChartColorsNumbers from '../components/StackedBarChartColorsNumbers'
import HorizontalBarChartNumbers from '../components/HorizontalBarChartNumbers'
import Card from '../components/Card'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Thema Ziekenhuisopnamen',
  },
  components: { Card, StackedBarChartColorsXT, StackedBarChartColorsNumbers, HorizontalBarChartNumbers, Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      thuis: [],
      verpleeghuis: [],
      overig: [],
      jaar_thuis: [],
      jaar_verpleeghuis: [],
      jaar_overig: [],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFiv: false,
        graphSix: false,
        graphSeven: false,
      },
      jaar_ziekenhuisopname :[],
      categorie_ziekenhuisopname :[],
      percentage_ziekenhuisopname :[],
      thuis_bst: [],
      verpleeghuis_bst: [],
      overig_bst: [],
      hospice_bst: [],
      revalidatiecentrum_bst: [],
      jaar_thuis_bst: [],
      jaar_verpleeghuis_bst: [],
      jaar_hospice_bst: [],
      jaar_revalidatiecentrum_bst: [],
      jaar_overig_bst: [],
      jaar: [],
      perc_minstens_een_opname: [],
      perc_minstens_een_opname_table: [],
      gemiddelde_opnames: [],
      ligduur: [],
      groep_ligduur: [],
      perc_acuut: [],
      perc_acuut_table:[],
      groep_acuut: [],
      categorie_opname :[], 
      groep_opname :[], 
      percentage_opname :[],
      dag: [],
      groep_dag: [],
      klinisch: [],
      groep_klinisch: [],
      observaties: [],
      groep_observaties: [],
      aantal_dementie: [],
      diagnose_dementie: [],
      aantal_65: [],
      diagnose_65: [],
      aantal_80: [],
      diagnose_80: [],
      jaar_wmo :[],
      categorie_wmo :[],
      percentage_wmo:[],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData () {
      this.$http.get(`/api/opname_herkomst/get_data/`).then(
        (response) => {
          this.jaar_thuis = response.data.filter(x => x.herkomst =='Thuis').map(d => d.jaar)
          this.thuis = response.data.filter(x => x.herkomst =='Thuis').map(d => (d.percentage))
          this.jaar_verpleeghuis = response.data.filter(x => x.herkomst =='Verpleeghuis').map(d => d.jaar)
          this.verpleeghuis = response.data.filter(x => x.herkomst =='Verpleeghuis').map(d => (d.percentage))
          this.jaar_overig = response.data.filter(x => x.herkomst =='Overig').map(d => d.jaar)
          this.overig = response.data.filter(x => x.herkomst =='Overig').map(d => (d.percentage))

          this.jaar_wmo = response.data.map(d => d.jaar)
          this.categorie_wmo = response.data.map(x => x.herkomst)
          this.percentage_wmo = response.data.map(d => (d.percentage).toFixed(1))
          this.load.graphOne=true
        }
      ),
      this.$http.get(`/api/opname_bestemming/get_data/`).then(
        (response) => {
          this.jaar_thuis_bst = response.data.filter(x => x.bestemming =='Thuis').map(d => d.jaar)
          this.thuis_bst = response.data.filter(x => x.bestemming =='Thuis').map(d => (d.percentage))
          this.jaar_verpleeghuis_bst = response.data.filter(x => x.bestemming =='Verpleeghuis').map(d => d.jaar)
          this.verpleeghuis_bst = response.data.filter(x => x.bestemming =='Verpleeghuis').map(d => (d.percentage))
          this.jaar_overig_bst = response.data.filter(x => x.bestemming =='Overig').map(d => d.jaar)
          this.overig_bst = response.data.filter(x => x.bestemming =='Overig').map(d => (d.percentage))
          this.jaar_hospice_bst = response.data.filter(x => x.bestemming =='Hospice').map(d => d.jaar)
          this.hospice_bst = response.data.filter(x => x.bestemming =='Hospice').map(d => (d.percentage))
          this.jaar_revalidatiecentrum_bst = response.data.filter(x => x.bestemming =='Revalidatie').map(d => d.jaar)
          this.revalidatiecentrum_bst = response.data.filter(x => x.bestemming =='Revalidatie').map(d => (d.percentage))

          this.jaar_ziekenhuisopname =  response.data.map(d => d.jaar)
          this.categorie_ziekenhuisopname = response.data.map(d => d.bestemming)
          this.percentage_ziekenhuisopname =response.data.map(d => (d.percentage).toFixed(1))
          this.load.graphTwo=true
        }
      ),
      this.$http.get('/api/opnames/get_data_dementie').then(
        (response) => {
          this.jaar = response.data.map(d => d.jaar)
          this.perc_minstens_een_opname = response.data.map(d => d.perc_een_opname)
          this.perc_minstens_een_opname_table = response.data.map(d => (d.perc_een_opname).toFixed(1))
          this.gemiddelde_opnames = response.data.map(d => d.gemiddelde_opnames.toFixed(2))
          this.load.graphThree = true
        }
      ),
      this.$http.get(`/api/opnames/get_data_ligduur/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.ligduur = response.data.map(d => d.ligduur_langdurig_klinisch.toFixed(2))
          this.groep_ligduur = response.data.map(d => d.groep)
          this.load.graphFour = true
        }
      ),
      this.$http.get(`/api/opnames/get_data_acuut/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.perc_acuut = response.data.map(d => d.perc_acuut)
          this.perc_acuut_table = response.data.map(d => (d.perc_acuut).toFixed(1))
          this.groep_acuut = response.data.map(d => d.groep)
          this.load.graphFive = true
        }
      ),
      this.$http.get(`/api/opname_soort/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.groep_dag = response.data.filter(x => x.soort_opname =='Dagopnamen').map(d => d.groep)
          this.dag = response.data.filter(x => x.soort_opname =='Dagopnamen').map(d => (d.percentage))
          this.groep_klinisch = response.data.filter(x => x.soort_opname =='Klinische opnamen').map(d => d.groep)
          this.klinisch = response.data.filter(x => x.soort_opname =='Klinische opnamen').map(d => (d.percentage))
          this.groep_observaties = response.data.filter(x => x.soort_opname =='Observaties').map(d => d.groep)
          this.observaties = response.data.filter(x => x.soort_opname =='Observaties').map(d => (d.percentage))

          this.categorie_opname =response.data.map(x => x.soort_opname)
          this.groep_opname = response.data.map(d => d.groep)
          this.percentage_opname =response.data.map(d => (d.percentage).toFixed(1))
          this.load.graphSix=true
        }
      ),
      this.$http.get(`/api/hoofddiagnose/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => b.rank-a.rank)
          this.diagnose_dementie = response.data.filter(x => x.groep =='Mensen met dementie').map(d => d.hoofddiagnose).slice(-10)
          this.aantal_dementie = response.data.filter(x => x.groep =='Mensen met dementie').map(d => (d.percentage).toFixed(2)).slice(-10)
          this.diagnose_65 = response.data.filter(x => x.groep =='Algemene bevolking 65+').map(d => d.hoofddiagnose).slice(-10)
          this.aantal_65 = response.data.filter(x => x.groep =='Algemene bevolking 65+').map(d => (d.percentage).toFixed(2)).slice(-10)
          this.diagnose_80 = response.data.filter(x => x.groep =='Algemene bevolking 80+').map(d => d.hoofddiagnose).slice(-10)
          this.aantal_80 = response.data.filter(x => x.groep =='Algemene bevolking 80+').map(d => (d.percentage).toFixed(2)).slice(-10)
          this.load.graphSeven=true
        }
      )
    }
  },
  mounted () {
    this.loadAllData()
  }
}

</script>
<style scoped>

.graphtext-big {
  height: 60px;
}


.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.intro-section-colour-block {
  background: #e3e7ed;
  width: 100%;
  padding: 5% 8% 8% 5%;
  color: #fff;
  position: relative;
  border: 5px solid #e3e7ed;
  border-radius: 15px;
}

.intro-section-cc {
  width: 100%;
  height: 100px;
  position: relative;
  left: 0px;
  background: linear-gradient(to right bottom, #e3e7ed 49%, #e3e7ed 50%),
    linear-gradient(-50deg, #ffffff 16px, #000 0);
  margin-top: -100px;
  border-radius: 15px;
}

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

/*.intro-section-text{
  color: #1f2a44;
}*/
</style>
